import Listener from './Listener';

const qs = new URLSearchParams( window.location.search );

const hostEls = window.location.hostname.split( '.' );
const isPresenter = (hostEls.indexOf( 'presenter' ) >= 0);
const publicURL = window.location.origin.replace( /presenter./ig, '' );

const privates = {
  presentation: null,
  presenter: isPresenter,
  id: qs.get('id') || '',
  alias: qs.get('alias') || ''
}

privates.publicURL = `${publicURL}?id=${privates.id}`;

class Session extends Listener {

  // Unique identifier for the session (autogenerated whenever host starts a presentation)
  get id() { return privates.id; }

  set id( value ) {
    const changed = privates.id !== value;
    if (changed) {
      privates.id = value;
      this.emit( 'join-session', privates );
    }
  }

  // Name/Nickname of viewer (optional)
  get alias() { return privates.alias; }

  set alias( value ) {
    privates.alias = value;
  }

  // The presentation
  get presentation() { return privates.presentation; }

  set presentation( value ) {
    privates.presentation = value;
  }

  // The URL that viewers can use to access the presentation
  get publicURL() {
    return privates.publicURL;
  }

  get isPresenter() {
    return privates.presenter;
  }

  get presentationTitle() {
    return privates.presentation ? privates.presentation.title : '';
  }

}

export default new Session();