import React from 'react';
import VR from './VR';
import Session from '../classes/Session';

import './Viewer.scss';

export default class Viewer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { joined: false }
    this.joinHandler = this.joinHandler.bind(this);
  }

  joinHandler() {
    this.setState( {joined: true } );
  }

  render() {

    if (this.state.joined === true) {
      return (
        <VR presenter={false} presentation={Session.presentation}></VR>
      )
    }

    return (
      <div className="Viewer">

        <div className="viewer-join-container">
          
          <div className="titlebar">
            <img className="imagination-logo" src="assets/imagination.png" alt="imagination logo" />
            <img className="meetvr-logo" src="assets/meetvr.svg" alt="meetVR logo" />
          </div>

          <p className="message">You are about to join <span className="title">{Session.presentationTitle}</span></p>

          <button onClick={this.joinHandler}>Join</button>

        </div>
      </div>
    )
  }

}