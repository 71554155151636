import React from 'react';
import Session from '../classes/Session';

import './Watermark.scss';

export default class Watermark extends React.Component {
    render() {
        if (Session.isPresenter) {
            return ''
        }

        return (
            <div className="Watermark">
                <img className="imagination-logo" src="assets/imagination.png" alt="imagination logo" />
                <img className="meetvr-logo" src="assets/meetvr.svg" alt="meetVR logo" />
            </div>
        )
    }
}