import React from 'react';
import Session from '../classes/Session';
import Content from '../classes/Content';
import Comms from '../classes/Comms';

import './PresenterStart.scss';

export default class PresenterStart extends React.Component {
  
  constructor(props) {
    super(props);

    this.publicURLRef = React.createRef();

    this.onStart = this.onStart.bind(this);

    this.onPresentationChange = this.onPresentationChange.bind(this);
    this.onCommsEvent = this.onCommsEvent.bind(this);

    this.state = {
      presentations: [],
      presentationData: null,
      selectedPresentationId: '',
      id: '',
      publicURL: ''
    }

  }

  componentDidMount() {
    Content.getPresentations()
      .then( results => this.setState( { 
        presentations: results
      } ) );

    Comms.addListener( this.onCommsEvent );
  }

  componentWillUnmount() {
    Comms.removeListener( this.onCommsEvent );
  }

  onCommsEvent( event, data ) {
    if (event === 'connected') {
      Comms.send( 'create-presentation', {
        presentationId: Session.id,
        data: this.state.presentationData
      });
      window.location.href = `${window.location.origin}?id=${Session.id}`;
    }
  }

  render() {

    const empty = <option value='' key=''>&laquo; Choose a Presentation &raquo;</option>

    const presentationOptions = [ empty, ...this.state.presentations.map( p =>       
      <option value={p.uuid} key={p.uuid}>{p.title}</option>
    )];

    return (
      <div className="PresenterStart">

        <div className="container">

          <div className="titlebar">
            <img className="imagination-logo" src="assets/imagination.png" alt="imagination logo" />
            <img  className="meetvr-logo" src="assets/meetvr.svg" alt="meetVR logo" />
          </div>

          <select className="select-presentation" onChange={this.onPresentationChange} value={this.state.selectedPresentationId}>{presentationOptions}</select>
          <button className="start" disabled={!this.state.selectedPresentationId} onClick={this.onStart}>Start Presentation</button>
        </div>
      </div>
    )
  }

  onPresentationChange(evt) {
    this.setState({ 
      selectedPresentationId: evt.target.value,
      presentationData: this.state.presentations.find( item => item.uuid === evt.target.value )
    });
  }

  get uniqueId() {
    return [1,2,3,4].map( () => String.fromCharCode( 97 + Math.floor(Math.random() * 26) ))
      .join( '' );
  }

  onStart() {
    Session.id = this.uniqueId;
    Comms.connect();
}
  
}