import React from 'react';
import VR from './VR';
import Scenes from './Scenes';
import Scene from './Scene';
import Session from '../classes/Session';
import Sidebar from './Sidebar';
import Header from './Header';

import './Presenter.scss';

export default class Presenter extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      scene: null
    }

    this.onViewSceneChange = this.onViewSceneChange.bind( this );
  }

  render() {

    return (
      <div className="Presenter">
        <Header></Header>
        <div className="main">
          <VR presenter={true} presentation={Session.presentation}></VR>
          <Sidebar></Sidebar>
        </div>
        <div className="scene-controls">
          <Scenes presentation={Session.presentation} onViewSceneChange={this.onViewSceneChange}></Scenes>
          <Scene scene={this.state.scene}></Scene>
        </div>
      </div>
    )
  }

  onViewSceneChange(scene) {
    this.setState({scene});
  }
}