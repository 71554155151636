class Listener {

  listeners = [];

  addListener( func, domain = '' ) {
    if (!this.listeners.find( listener => listener.func === func ) ) {
      this.listeners.push( { func, domain } );
    }
  }

  removeListener( func ) {
    const index = this.listeners.findIndex( listener => listener.func === func );
    if (index>=0) {
      this.listeners.splice( index, 1 );
    }
  }

  emit( evt, data, domain = '' ) {
    this.listeners
      .filter( listener => listener.domain === domain )
      .forEach( ({ func }) => func(evt, data ) );
  }

}

export default Listener;