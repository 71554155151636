/*global StoryblokClient*/

import Config from './Config';

// Grab just the content, but include the uuid
const mapContentResults = (results) => results.map( row => {
  const retval = row.content;
  retval.uuid = row.uuid;
  return retval;
});

// Grab just the content, but include the uuid (for a single story request)
const mapContentStory = (result) => {
  const retval = result.data.story.content;
  retval.uuid = result.data.story.uuid;
  return retval;
};

// Map to Array
const mapToArray = ( results ) => results.reduce( (accum, row ) => {
  accum.push( row );
  return accum;
}, [] );

const sortBy = ( values, fieldname ) => values.sort( (a,b) => (a[fieldname]||'').localeCompare(b[fieldname]||''));

class Content {

  constructor() {
    this.storyblok = new StoryblokClient({
      accessToken: Config.sb_token
    })
  }

  getPresentations() {
    return this.storyblok.getAll('cdn/stories', {
      filter_query: {
        component: {
          in: 'presentation'
        }
      }
    })
    .then( mapContentResults )
    .then( mapToArray )
    .then ( array => array.filter( item => item.enabled ) )
    .then ( array => {
      array.forEach( presentation => {
        presentation.scenes = presentation.scenes.filter( scene => scene.enabled );
      })
      return array;
    })
    .then( values => sortBy( values, 'title' ) )
  }

  getPresentation( id ) {
    return this.storyblok.get( `cdn/stories/${id}?find_by=uuid` )
      .then( mapContentStory )
      .then ( presentation => {
        presentation.scenes = presentation.scenes.filter( scene => scene.enabled );
        return presentation;
      })
  }

}

export default new Content();