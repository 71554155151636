/*global AFRAME */
import Session from './Session';
import Comms from './Comms';
import VR from '../components/VR';
import State from './State';
import Highlight from './Highlight';

class Camera {

  constructor() {

    window.addEventListener( 'keypress', (evt) => {
      if (evt.code==="KeyX") {
        this.zoomIn();
      }
    })

    AFRAME.registerComponent( 'camera-commands', {
      init: function() {

        const camera = this.el;

   //     camera.setAttribute( 'zoom', '1' );

        Comms.addListener( (event, params) => {

          switch (event) {

            case 'camera.set-rotation': 

              if (!Session.isPresenter) {

                const sceneEl = this.el.sceneEl;
                Comms.emit( 'highlight.set-visibility', false );

                // First Fade out
                VR.instance.fadeOut().then( () => {

                  const isVR = sceneEl.is('vr-mode') && sceneEl.checkHeadsetConnected();
                  const cameraRotation = document.getElementById( 'camera-rotation' );
                  const lookControls = camera.components[ 'look-controls' ];

                  if (isVR) {
                    cameraRotation.object3D.rotation.y = params.y- this.el.object3D.rotation.y;
                  } else {
                    lookControls.pitchObject.rotation.x = params.pitch;              
                    lookControls.yawObject.rotation.y = params.yaw;
                    lookControls.pitchObject.rotation.z = 0;
                  }
              
                  if (params.highlight) {
                    Highlight.position = params.highlightPosition;
                  }

                  VR.instance.fadeIn();
                  
                  Comms.emit( 'highlight.set-visibility', true );
                });

              }
              break;

            default:
              break;
          }
        });

      }
    });

  }

  get cameraEl() {
    return document.getElementById( 'camera' );
  }

  get skyEl() {
    return document.getElementById( 'sky' );
  }

  setRotation( highlight = false) {
    if (Session.isPresenter) {
      const camera = this.cameraEl;
      const lookControls = camera.components[ 'look-controls' ];

      Comms.broadcast( 'camera.set-rotation', {
        pitch: lookControls.pitchObject.rotation.x,
        yaw: lookControls.yawObject.rotation.y,
        y: camera.object3D.rotation.y,
        highlight: highlight,
        highlightPosition: highlight ? Highlight.CameraDirection : null
      });
    }
  }

  goHome( yaw = 0 ) {
    if (Session.isPresenter) {  
      const lookControls = this.cameraEl.components[ 'look-controls' ];

      lookControls.pitchObject.rotation.x = 0;              
      lookControls.yawObject.rotation.y = 0;
      lookControls.pitchObject.rotation.z = 0;

      this.setRotation();
    }
  }

  startZoom() {
    return new Promise( resolve => {
      if (!Session.isPresenter) {
        this.resetZoom();
        if (State.activeSceneId) {
          this.cameraEl.setAttribute( 'animation__zoom', `property: camera.zoom; to: 1.75; dur: 40000; easing: linear;` );
        }
      }
      resolve();
    });
  
  }

  stopZoom() {
    const anim = this.cameraEl.components['animation__zoom'];
    if (anim) {
      anim.remove();
      this.cameraEl.removeAttribute( 'animation__zoom' );
    }
  }

  resetZoom() {
    return new Promise( (resolve) => {
      this.stopZoom();
      this.cameraEl.setAttribute( 'camera', 'zoom', 1 );
      resolve();
    })
  }

}

export default new Camera();