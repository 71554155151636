import React from 'react';
import State from '../classes/State';
import Camera from '../classes/Camera';
import Highlight from '../classes/Highlight';
import Watermark from './Watermark';

import './VR.scss';

const videoRegEx = /(mp4)$/ig;
let instance = null;

export default class VR extends React.Component {

  constructor(props) {
    super(props);
    
    instance = this;
    
    this.skyRef = React.createRef();
    this.cursorRef = React.createRef();
    this.highlightRef = React.createRef();

    this.camera = Camera;
    this.highlight = Highlight;
    this.setSkyPosition = true;
    this.onStateEvent = this.onStateEvent.bind(this);
    this.previousVideo = null;

    this.state = { 
      fadeout: false 
    };

  }

  static get instance() {
    return instance;
  }

  componentDidMount() {
    State.addListener( this.onStateEvent );
    this.startMedia();
  }

  componentWillUnmount() {
    State.removeListener( this.onStateEvent );
  }

  startMedia() {

    const vid = document.getElementById( State.activeSceneId );
    if (this.previousVideo) {
      this.previousVideo.pause();
      this.previousVideo = null;
    }
    if (vid && vid.play) {
      vid.play().catch(e => console.log(e.toString()));
      this.previousVideo = vid;
    } else {
      if (State.activeSceneId) {
        Camera.startZoom();
      }
    }
  }

  onStateEvent( event, data ) {
    switch (event) {
      case 'loaded':
      case 'activeSceneIdChanged':
        this.fadeOut().then( () => {
          this.setSkyPosition = true;
          this.forceUpdate();
          this.startMedia();
          this.fadeIn();
        })
        break;
      default:
        break;
    }
  }

  render() {

    let sky_position = 0;
    let sky_id = '';

    const showHolding = !State.activeSceneId;

    if (showHolding) {
      const hasHolding = this.props.presentation.holding_image && this.props.presentation.holding_image.filename;
      sky_id = hasHolding ? '__holding__' : '';
      sky_position = hasHolding ? parseInt( this.props.presentation.holding_position ) || 0 : 0;
    } else {
      sky_id = State.activeSceneId;
      sky_position = 0;
    }

    const holding_url = this.props.presentation.holding_image && this.props.presentation.holding_image.filename ?
      this.props.presentation.holding_image.filename : '';

    const assetList = this.props.presentation.scenes.map( scene => {
      if (scene.image.filename.match( videoRegEx )) {

        //const autoPlay = scene._uid === sky_id;
        return <video crossOrigin="anonymous" id={scene._uid} loop={true} src={scene.image.filename} key={scene._uid}> </video>
      } else {
        return <img crossOrigin="anonymous" id={scene._uid} src={scene.image.filename} alt="scene" key={scene._uid}/>
      }
    })

    assetList.push(
      <img crossOrigin="anonymous" id="__holding__" src={holding_url} alt="scene" key="__holding__"/>
    );
    const rotation = `0 ${sky_position} 0`;

    let classnames = this.props.presenter ? 'VR is-presenter' : 'VR';
    classnames += this.state.fadeout ? ' fadeout' : '';

    return (
      <div className="vr-outer">
        <div className={classnames}>

          <a-scene loading-screen="dotsColor: white; backgroundColor: black" {...( this.props.presenter && { embedded: true, 'vr-mode-ui': 'enabled:false; enterARButton: #customARButton' } )} id="scene" inspector="https://cdn.jsdelivr.net/gh/aframevr/aframe-inspector@master/dist/aframe-inspector.min.js">
          
            <a-assets id="assets">{assetList}</a-assets>
            
            <a-sky ref={this.skyRef} id="sky" src={'#'+sky_id} rotation={rotation}></a-sky>

             <a-entity 
              ref={this.highlightRef}
              id="highlight" 
              geometry="primitive: circle"
              material="color: yellow; shader: flat; opacity:0.25;"
              scale="0.75 0.75 0.75"
              position="0 0 -10"
              rotation="0 0 90"
              billboard
              highlight
            ></a-entity>
        
            <a-entity rotation="0 0 0" id="camera-rotation">
              <a-camera id="camera" position="0 0 0" camera-commands>  
                <a-entity
                  ref={this.cursorRef}
                  id="cursor"
                  raycaster="objects: .clickable"
                  cursor="fuse: true; fuseTimeout:1500"
                  material="color: white; shader: flat;"
                  position="0 0 -3"
                  rotation="0 0 90"
                  geometry="primitive: ring; thetaLength:360"
                  scale="0.05 0.05 0.05"
                  animation__fusing_ring="property: geometry.thetaLength; startEvents: fusing; dur: 1500; to: 0;"  
                  animation__mouseleave_ring="startEvents: mouseleave; property: geometry.thetaLength; dur: 100; to: 360;"
                ></a-entity>
              </a-camera>
            </a-entity>
          </a-scene>

          <Watermark></Watermark>
        </div>

        <div id="customARButton"></div>

      </div>
    );
    
  }

  fade( out ) {
    return new Promise( (resolve) => {
      const duration = 500;
      const value = out ? 0 : 1;
      const highlightValue = out ? 0 : 0.25;
      const attr = `property: material.opacity; to:${value}; dur:${duration};`;
      const attrHighlight = `property: material.opacity; to:${highlightValue}; dur:${duration};`;

      this.skyRef.current.setAttribute( 'animation__fade', attr );
      this.cursorRef.current.setAttribute( 'animation__fade', attr );
      this.highlightRef.current.setAttribute( 'animation__fade', attrHighlight );
      setTimeout( resolve, duration );
    });
  }

  fadeOut() {
    return this.fade(true)
      .then( () => Camera.resetZoom() );
  }

  fadeIn() {
    return Camera.startZoom()
      .then( () => this.fade(false) );
  }
}
