import React from 'react';

import './ViewerNoSession.scss'

export default class ViewerNoSession extends React.Component {

  render() {

    return (
      <div className="ViewerNoSession">

        <div className="viewer-join-container">
          
          <div className="titlebar">
            <img className="imagination-logo" src="assets/imagination.png" alt="imagination logo" />
            <img className="meetvr-logo" src="assets/meetvr.svg" alt="meetVR logo" />
          </div>
          
        </div>
      </div>
    )

  }

}