/*global AFRAME, THREE */
import Comms from './Comms';
import State from './State';

class Highlight {

  constructor() {
    this.visibility = true;
    this._position = null;

    const self = this;

    AFRAME.registerComponent( 'highlight', {

      init: function() {

        this.el.setAttribute( 'visible', false );

        State.addListener( (event) => {
          if (event==='activeSceneIdChanged' || event==='loaded') {
            this.el.setAttribute( 'visible', false);
          }
        });

        Comms.addListener( (event, params) => {
          switch (event) {
            case 'highlight.hide':
              self.position = {x:-1,y:-1,z:-1}
              break;

            default:
              break;
          }
        });
      }
    });

  }

  hide() {
    Comms.broadcast( 'highlight.hide' );
    const el = document.getElementById( 'highlight' );
    el.setAttribute( 'visible', false );
  }

  get highlightEl() {
    return document.getElementById( 'highlight' );
  }

  set position( value ) {
    const hidden = value.x===-1 && value.y===-1 && value.z===-1;
    const el = this.highlightEl;

    if (!hidden) {
      // When used on a headset, the highlight y-coord appears slightoff! So we need              
      // offset the y by a tad!
      if (el.sceneEl.is('vr-mode') && el.sceneEl.checkHeadsetConnected()) {
        value.y += 1.5;
      }  
    }

    this._position = value;

    if (!hidden) {
      this.highlightEl.setAttribute( 'position', this._position );
      this.highlightEl.setAttribute( 'visible', this._position );
    } else {
      this.highlightEl.setAttribute( 'position', value );
      this.highlightEl.setAttribute( 'visible', false );
    }
  }

  get position() {
    return this._position;
  }

  get CameraDirection() {
    const direction = new THREE.Vector3();
    const camera = document.getElementById( 'camera' );

    camera.object3D.getWorldDirection( direction );

    direction.multiplyScalar(-10);

    const x = direction.x
    const y = direction.y; // comment this to get 2D movement
    const z = direction.z
    
    return {x,y,z};
  }

  setToCamera() {
    this.position = this.CameraDirection;
  }

}

export default new Highlight();