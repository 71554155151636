const stage = {
  "ws": "wss://642fjxuobh.execute-api.eu-west-1.amazonaws.com/stage"
}

const live = {
  "ws": "wss://ruefb07pff.execute-api.eu-west-1.amazonaws.com/live"
}

const config = process.env.REACT_APP_STAGE === 'live'
  ? live
  : stage;

export default {
  "sb_token": "AvYSFPiOovnKkzPYLW4Rpgtt",
  ...config
}