import React from 'react';
//import Scene from './Scene';
import State from '../classes/State';

import './Scenes.scss';
//import Session from '../classes/Session';

export default class Scenes extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedScene: null
    }

    this.onStateEvent = this.onStateEvent.bind(this);
  }

  componentDidMount() {
    State.addListener( this.onStateEvent );

    // Show the active scene
    this.showScene( State.activeScene );

  }

  componentWillUnmount() {
    State.removeListener( this.onStateEvent );
  }

  onStateEvent( event, data ) {
    switch (event) {
      case 'activeSceneIdChanged':
        this.forceUpdate();
        break;
      default:
        break;
    }
  }

  showScene( scene ) {
    this.setState({selectedScene:scene});
    if (this.props.onViewSceneChange) {
      this.props.onViewSceneChange( scene );
    }
  }

  render() {
    const scenes = this.props.presentation.scenes.map( scene => {

      const selected = this.state.selectedScene && this.state.selectedScene._uid === scene._uid;
      
      const icon = selected ?
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path fill="white" d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/></svg> :
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path  d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/></svg>;
      
      const className = ['item', 
        (scene._uid === State.activeSceneId ? 'active' : ''),
        (selected ? 'selected' : '')
      ].join( ' ' );
        
      return (
        <div className={className} onClick={()=>this.showScene(scene)} key={scene._uid}>
          <p className="title">{ scene.title }</p>
          <div className="icon-container">{icon}</div>
        </div>
      )
      });

    return (
      <div className="Scenes">
        <h1>360 Scenes</h1>
        <div className="list">{scenes}</div>
      </div>
    )
  }
}