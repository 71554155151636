import React from 'react';
import Session from '../classes/Session';
import './PublicURL.scss';

const NOOP = () => {};

export default class PublicURL extends React.Component {

  constructor(props) {
    super(props);

    this.urlRef = React.createRef();
    this.hiddenRef = React.createRef();
    this.onCopyPublicURL = this.onCopyPublicURL.bind( this );
  
  }

  render() {
    const html =  
      <div className="PublicURL">
        <input className="url" value={Session.publicURL} onChange={NOOP} ref={this.urlRef} />
        <img className="copy" src="assets/copy.svg" alt="Copy Address" onClick={this.onCopyPublicURL}/>
        <input className="hidden" readOnly ref={this.hiddenRef}/>
      </div>;

    return Session.id ? html : '';
  }

  onCopyPublicURL(evt) {
    this.urlRef.current.select();
    document.execCommand("copy");
    this.urlRef.current.setSelectionRange(0, 0);
    this.hiddenRef.current.focus();
  }

}