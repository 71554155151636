import React from 'react';
import State from '../classes/State';

import './Scene.scss';

export default class Scene extends React.Component {

  constructor(props) {
    super(props);
    
    this.onActivateScene = this.onActivateScene.bind( this );
    this.onStateEvent = this.onStateEvent.bind( this );
  }

  componentDidMount() {
    State.addListener( this.onStateEvent );
  }

  componentWillUnmount() {
    State.removeListener( this.onStateEvent );
  }

  onStateEvent(event, data) {
    if (event === 'activeSceneIdChanged') {
      this.forceUpdate();
    }
  }

  render() {

    const { scene } = this.props;
    
    if (!scene) {
      return '';
    }

    const button = scene._uid === State.activeSceneId ? '' :
      <button className="activate" onClick={this.onActivateScene}>
        <span>Activate</span>
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/></svg>
      </button>;

    return scene ? (
      <div className="Scene">
        <h1>{scene.title}</h1>
        <p className="description">{scene.description}</p>
        {button}
      </div>
    ) : ''
  }

  onActivateScene() {
    State.activeSceneId = this.props.scene._uid;   
  }
}